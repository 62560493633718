<template>
    <div>
        <router-link
            class="lien-retour"
            :to="{name: 'TableauDeBord'}"
        >
            <i class="fas fa-chevron-left"></i> Retour vue d'ensemble
        </router-link>
        <div class="cadre-tete cadre-tete--menu">
            SUIVI PAR COLLABORATEURS

            <filters
                :filtrer="filtrer"
                :api="'suiviParIndividuCharger'"
                :actions="['export']"
                :defaultData="{dateDebut, dateFin}"
            ></filters>

        </div>
        <div class="cadre-content background-gray">
            <div>
                <div class="individus">
                    <div class="cadre kpis"
                         v-for="personne in personnes"
                         :key="personne.id"
                    >
                        <div class="cadre-content">
                            <div class="individu-nom">
                                <div>
                                    {{ personne.nom }}
                                </div>
                            </div>
                            <div>
                                <div class="kpi">
                                    <router-link
                                        tag="div"
                                        :to="{name: 'DossiersEncaisses', query: {
                                          intervenant: personne.id,
                                          dateDebut,
                                          dateFin
                                        }}"
                                    >
                                        <label>Total encaissé</label>
                                        {{personne.total_encaisse}} {{$compte.societe_devise}}
                                    </router-link>
                                </div>

                                <div class="kpi">
                                    <router-link
                                        tag="div"
                                        :to="{name: 'DossiersFactures', query: {
                                          intervenant: personne.id,
                                          dateDebut,
                                          dateFin
                                        }}"
                                    >
                                        <label>Total facturé</label>
                                        {{personne.total_facture}} {{$compte.societe_devise}}
                                    </router-link>
                                </div>

                                <div class="kpi">
                                    <router-link
                                        tag="div"
                                        :to="{name: 'DossiersPrevisionnels', query: {
                                          intervenant: personne.id,
                                          dateDebut,
                                          dateFin
                                        }}"
                                    >
                                        <label>Total prévisionnel</label>
                                        {{personne.total_previsionnel}} {{$compte.societe_devise}}
                                    </router-link>

                                </div>

                                <div class="kpi">
                                    <router-link
                                        tag="div"
                                        :to="{name: 'DossiersEnCours', query: {
                                          intervenant: personne.id,
                                          dateDebut,
                                          dateFin
                                        }}"
                                    >
                                        <label>Total potentiel</label>
                                        {{personne.total_potentiel}} {{$compte.societe_devise}}
                                    </router-link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
  import api from '@/api'
  import dayjs from 'dayjs'

  export default {
    name: 'SuiviIndividu',
    data() {
      return {
        personnes: [],
        dateDebut: dayjs().startOf('year').format('YYYY-MM-DD'),
        dateFin: dayjs().format('YYYY-MM-DD'),
      }
    },
    created() {
      api.suiviParIndividuCharger({debut: this.dateDebut, fin: this.dateFin}).then((d) => {
        this.personnes = d.data
      })
    },
    methods: {
      filtrer(data) {
        this.personnes = data.data
        this.dateDebut = data.debut
        this.dateFin = data.fin
      },
    }
  }
</script>

<style lang="scss" scoped>

    @import "~courtisia-style/scss/colors";

    .cadre {
        height: auto !important;
        background: white;
    }

    .kpis {
        label {
            font-weight: 100;
        }

        .cadre-content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
        }

        .kpi {
            &:hover {
                cursor: pointer !important;
                color: $primary;

                label {
                    cursor: pointer;
                }
            }

            @media only screen and (max-width: 850px) {
                width: 45%;
                padding: 10px;
            }

            @media only screen and (max-width: 650px) {
                width: 100%;
                padding: 10px;
            }
        }

        .kpi-value {
            font-size: 1.2rem;
        }
    }

    .individus {

        .individu-nom {
            width: 125px;
            display: flex;
            align-items: center;
            text-transform: uppercase;

            img {
                margin-right: 10px;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                flex-shrink: 0;
            }

            small {
                color: gray;
                text-transform: none;
            }
        }

        .individu-nom + div {
            display: flex;
            flex-wrap: wrap;
            width: calc(100% - 125px);
            justify-content: space-around;
        }

        .kpis:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .lien-retour {
        text-decoration: none;
        color: $gray;
        display: block;
        padding: 10px;
    }



</style>
