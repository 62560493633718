<template>
    <div>
        <router-link
            class="lien-retour"
            :to="{name: 'TableauDeBord'}"
        >
            <i class="fas fa-chevron-left"></i> Retour vue d'ensemble
        </router-link>
        <div class="cadre">
            <div class="cadre-tete cadre-tete--menu">
                DOSSIERS EN COURS

                <filters
                    api="dossiersEnCoursCharger"
                    :filtrer="filtrer"
                    :actions="['intervenant', 'export']"
                    :pagination="true"
                    :defaultData="{intervenant, dateDebut, dateFin}"
                >

                </filters>
            </div>
            <div class="cadre-content">
                <table class="table table-collapsable">
                    <thead>
                    <tr>
                        <th>Intervenant</th>
                        <th>Dossier</th>
                        <th>Apporteur</th>
                        <th>Banque</th>
                        <th>Date de saisie</th>
                        <th class="number">CA Brut potentiel</th>
                        <th class="number">CA Net potentiel</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <template
                        v-for="(row, idx) in rows"
                    >
                        <!--<tr
                            class="row"
                            @click="ligneDeplier(idx)"
                        >-->
                        <tr class="row">
                            <td>{{row.intervenant}}</td>
                            <td>
                                <div v-html="row.dossier"></div>
                                <small>{{row.dossier_numero}}</small>
                            </td>
                            <td>
                                <div>{{row.apporteur}}</div>
                                <small>{{row.apporteur_contact}}</small>
                            </td>
                            <td>
                                <div>{{row.banque_agence}}</div>
                                <small>{{row.banque_groupe}}</small>
                            </td>
                            <td>
                                {{row.date_saisie}}
                            </td>
                            <td class="number">
                                {{row.potentiel_brut}} {{$compte.societe_devise}}
                            </td>
                            <td class="number">
                                {{row.potentiel_net}} {{$compte.societe_devise}}
                            </td>
                            <td class="center">
                                <i
                                    class="fas fa-folder fa-2x"
                                    @click="dossierCliquer(row.id)"
                                ></i>
                            </td>
                        </tr>
                        <!--<tr class="row-expand">
                            <td colspan="6">
                                <transition
                                    name="row-expand"
                                    v-on:before-enter="beforeEnter"
                                    v-on:enter="enter"
                                    v-on:before-leave="beforeLeave"
                                    v-on:leave="leave"
                                >
                                    <div class="row-expand-content" v-show="lignesOuvertes.includes(idx)">
                                        <div>
                                            <div
                                                class="etape"
                                                v-for="etape in etapes[row.dossier_id]"
                                                :key="etape.id"
                                            >{{etape.libelle}}
                                            </div>
                                        </div>
                                    </div>
                                </transition>
                            </td>
                        </tr>-->
                    </template>
                    </tbody>
                </table>
                <div class="pagination-container">
                    <paginate
                        :page-count="Math.ceil(total / limite)"
                        :click-handler="clickPage"
                        :prev-text="'&#10094;'"
                        :next-text="'&#10095;'"
                        :container-class="'pagination'">
                    </paginate>
                </div>
                <div
                    class="background-loading"
                    v-if="loading"
                ></div>
            </div>
        </div>
    </div>


</template>

<script>
  import api from '@/api'
  import dayjs from 'dayjs'
  import Filters from "../components/Filters.vue";

  export default {
    components: {Filters},
    name: 'DossiersEnCours',
    created() {
      const {intervenant, dateDebut, dateFin} =  this.$route.query || {}
      this.intervenant = intervenant
      this.dateDebut = dateDebut ? dateDebut : this.dateDebut
      this.dateFin = dateFin ? dateFin : this.dateFin
      this.charger()
    },
    data() {
      return {
        lignesOuvertes: [],
        etapes: {},
        rows: [],
        total: 0,
        limite: 10,
        page: 1,
        loading: false,
        dateDebut: dayjs().startOf('year').format('YYYY-MM-DD'),
        dateFin: dayjs().format('YYYY-MM-DD'),
        intervenant: null,
      }
    },
    methods: {
      dossierCliquer(dossierId) {
        const e = new CustomEvent('dossierCharger', {detail: {dossierId}})
        window.dispatchEvent(e)
      },
      ligneDeplier(idx) {
        if (this.lignesOuvertes.indexOf(idx) > -1) {
          this.lignesOuvertes = this.lignesOuvertes.filter(_idx => _idx !== idx)
        } else {
          this.etapesDossierCharger(this.rows[idx].dossier_id).then(() => {
            this.lignesOuvertes.push(idx)
          })
        }
      },
      etapesDossierCharger(dossierId) {
        return api.dossierEtapesCharger(dossierId).then((d) => {
          this.etapes = {
            [dossierId]: d
          }
        })
      },
      graphiqueCharger() {
        api.dossiersEnCoursGraphiqueCharger().then(d => {
          this.chartOptions.series[0].data = d.data;
        })
      },
      beforeEnter(el) {
        el.style.height = '0px'
      },
      enter: function (el) {
        el.style.height = `${el.scrollHeight}px`
      },
      beforeLeave(el) {
        el.style.height = `${el.scrollHeight}px`
      },
      leave(el) {
        el.style.height = `0px`
      },
      clickPage(page) {
        this.page = page
        this.charger()
      },
      charger() {
        api.dossiersEnCoursCharger({
            page: this.page,
            limite: this.limite,
            debut: this.dateDebut,
            fin: this.dateFin,
            intervenant: this.intervenant
          }
        ).then((d) => {
          const {total, data} = d.data
          this.rows = data
          this.total = total
        })
      },
      filtrer(data) {
        this.rows = data.data
        this.total = data.total
        this.dateDebut = data.debut
        this.dateFin = data.fin
        this.intervenant = data.intervenant
        this.page = 1
      }
    }
  }
</script>

<style lang="scss" scoped>

    @import "~courtisia-style/scss/colors";

    .cadre {
        height: auto !important;
    }

    .row-expand-content {
        border-bottom: 1px solid #E7E7E7;
        > div {
            padding: 10px;
            display: flex;
            overflow-x: auto;
            width: 100%;
        }

        .etape {
            padding: 10px;
            width: 100px;
            height: 100px;
            background: #ba3b35;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }

    .lien-retour {
        text-decoration: none;
        color: $gray;
        display: block;
        padding: 10px;
    }

    .pagination-container {
        display: flex;
        justify-content: center;
        padding: 10px;
    }

    .table {
        font-size: .75rem;
    }
</style>
