<template>

    <form
        class="filters"
        @submit="filtrerAction"
    >
        <div class="filter filter-intervenant" v-if="$compte.intervenants_visibilite">
            <select
                class="form-control"
                v-if="actions.indexOf('intervenant') > -1"
                v-model="intervenant"
            >
                <option :value="null">Sélectionnez</option>
                <option v-for="inter in intervenants"
                        :value="inter.id">{{inter.lib}}
                </option>
            </select>
        </div>
        <div class="filter-dates">
            <div class="filter">
                <datepicker
                    input-class="form-control"
                    v-model="dateDebut"
                    format="DD/MM/YYYY"
                    :typeable="true"
                ></datepicker>
            </div>
            <div class="filter">
                <datepicker
                    input-class="form-control"
                    v-model="dateFin"
                    format="DD/MM/YYYY"
                    :typeable="true"
                ></datepicker>
            </div>
        </div>
        <div class="actions">
            <button
                class="btn btn-primary"
                type="submit"
            >Filtrer
            </button>
            <button
                class="btn btn-default btn-exporter"
                v-if="actions.indexOf('export') > -1"
                @click="exporter"
            >
                <i class="fas fa-download"></i> Exporter
            </button>
        </div>
    </form>

</template>

<script>
  import Datepicker from 'vuejs-datepicker';
  import dayjs from 'dayjs';
  import api from '@/api'
  import saveAs from 'file-saver'

  export default {
    name: 'Filters',
    components: {
      Datepicker
    },
    props: {
      filtrer: Function,
      api: String,
      actions: Array,
      pagination: Boolean,
      defaultData: Object,
    },
    data() {
      return {
        dateDebut: dayjs().startOf('year').toDate(),
        dateFin: dayjs().toDate(),
        intervenants: [],
        intervenant: null,
      }
    },
    created() {
      const {intervenant, dateDebut, dateFin} = this.defaultData || {}
      this.intervenant = intervenant || this.intervenant
      this.dateDebut = dateDebut || this.dateDebut
      this.dateFin = dateFin || this.dateFin
      if (this.actions.indexOf('intervenant') > -1) {
        api.intervenantsCharger().then(d => {
          this.intervenants = d.data
        })
      }
    },
    methods: {
      filtrerAction(e) {
        e.preventDefault()
        const defaultParams = this.pagination ? {page: 1} : {}
        api[this.api]({
          ...defaultParams,
          ...this.formatQS()
        }).then(d => {
          const data = (d.data instanceof Array) ? {data: d.data} : {...d.data}
          this.filtrer({
            ...data,
            ...this.formatQS()
          })
        })
      },
      exporter() {
        api[this.api]({
          ...this.formatQS(),
          csv: true
        }).then(d => {
          const blob = new Blob([d.data], {type: "text/csv;charset=utf-8"});
          saveAs(blob, "export.csv");
        })
      },
      formatQS() {
        return {
          intervenant: this.intervenant,
          debut: dayjs(this.dateDebut).format('YYYY-MM-DD'),
          fin: dayjs(this.dateFin).format('YYYY-MM-DD'),
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

    .filters {
        display: flex;

        @media only screen and (max-width: 768px){
            flex-wrap: wrap;
            width: 100%;
            margin: 0;
        }
    }

    .actions {
        display: flex;


        @media only screen and (max-width: 768px){
            width: 100%;
        }

        .btn {
            height: 38px !important;

            @media only screen and (max-width: 768px){
                width: 100%;
                max-width: inherit !important;
            }
        }
    }

    .filter-dates {
        display: flex;
        @media only screen and (max-width: 768px){
            width: 100%;
            margin-bottom: 10px;
        }

        .filter {
            margin-right: 10px;

            @media only screen and (max-width: 768px){
                width: 50%;
                margin: 0;
            }
        }
    }

    .filter-intervenant {
        @media only screen and (max-width: 768px){
            width: 100%;
        }
    }

    .btn-exporter {
        margin-left: 10px;
    }
</style>

<style>
   .vdp-datepicker__calendar {
        position: fixed !important;
    }
</style>
