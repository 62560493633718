import 'custom-event-polyfill/polyfill'
import Vue from 'vue'

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import router from '@/router'
import App from './App.vue'

//import { Vuetable, VueTablePagination, VueTablePaginationDropDown, VueTablePaginationInfo } from 'vuetable-2';
import Vuetable from 'vuetable-2'
Vue.component("vuetable", Vuetable);

import VueNumeric from 'vue-numeric'
Vue.use(VueNumeric)

import { Compte } from '@/utils'
Vue.use(Compte);

import HighchartsVue from 'highcharts-vue'
Vue.use(HighchartsVue);

import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

import Filters from '@/components/Filters'
Vue.component('filters', Filters)

import '@/filters';

import pace from 'pace-js-amd-fix/pace'

pace.start();

Vue.config.productionTip = false


window.suiviVueApp = function() {
  return new Vue({
    router,
    render: h => h(App),
  });//.$mount('.vuejs_app')*/
}

if (document.querySelector('.vuejs_app')) {
  window.suiviVueApp().$mount('.vuejs_app')
}
